@import "./variables";

@font-face {
  font-family: "Regular";
  src        : url("../fonts/Mardoto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style : normal;
}

@font-face {
  font-family: "Bold";
  src        : url("../fonts/Mardoto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style : normal;
}

@font-face {
  font-family: "Medium";
  src        : url("../fonts/Mardoto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style : normal;
}

* {
  margin     : 0;
  padding    : 0;
  color      : $black;
  box-sizing : border-box;
  outline    : 0;
  font-family: "Medium", "Bold", "Regular";
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, $blue, $green);
}

.G-relative {
  position: relative;
}

.G-capitalize {
  text-transform: capitalize;
}

.G-main-color {
  color: $main-color;
}

.G-red-color {
  color: $red;
}

.G-green-color {
  color: $green;
}

.G-gray-color {
  color: $gray;
}

.G-bold {
  font-weight: bold;
}

.G-no-bold {
  font-weight: 100;
}

.G-gray-dark-color {
  color: $gray-dark;
}

.G-blue-dark-color {
  color: $blue-dark;
}

.G-half {
  width: calc(50% - 20px) !important;
}

.G-text-center {
  text-align: center;
}

.G-cursor {
  cursor: pointer;
}

.G-red {
  background-color: #af001e;
}

.G-green {
  color: #0bbf6b;
}

// #region Font Size

$fonts: (10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 26, 28);

@each $size in $fonts {
  .G-fs-#{$size} {
    font-size: #{$size + px};
  }
}

// #endregion

// #region Flex

.G-flex {
  display: flex;
}

.G-center {
  justify-content: center;
  align-items    : center;
}

.G-justify-center {
  justify-content: center;
}

.G-justify-between {
  justify-content: space-between;
}

.G-justify-around {
  justify-content: space-around;
}

.G-justify-end {
  justify-content: flex-end;
}

.G-align-center {
  align-items: center;
}

.G-align-start {
  align-items: flex-start;
}

.G-align-end {
  align-items: flex-end;
}

.G-flex-column {
  flex-direction: column;
}

.G-flex-wrap {
  flex-wrap: wrap;
}

.G-page {
  width     : 100%;
  min-height: 100vh;
  padding   : 120px;
  position  : relative;
}

@media screen and (max-width: 1500px) {
  .G-page {
    padding: 40px;
  }
}

.G-mb-1 {
  margin-bottom: 10px;
}

.G-mb-2 {
  margin-bottom: 20px;
}

.G-mb-3 {
  margin-bottom: 30px;
}

.G-mb-4 {
  margin-bottom: 40px;
}

.G-mb-5 {
  margin-bottom: 50px;
}

.G-mb-6 {
  margin-bottom: 60px;
}

.G-mt-1 {
  margin-top: 10px;
}

.G-mt-2 {
  margin-top: 20px;
}

.G-mt-3 {
  margin-top: 30px;
}

.G-mt-4 {
  margin-top: 40px;
}

.G-mt-5 {
  margin-top: 50px;
}

.G-mt-6 {
  margin-top: 60px;
}

.G-mr-1 {
  margin-right: 10px;
}

.G-mr-2 {
  margin-right: 20px;
}

.G-mr-3 {
  margin-right: 30px;
}

.G-mr-4 {
  margin-right: 40px;
}

.G-mr-5 {
  margin-right: 50px;
}

.G-mr-6 {
  margin-right: 60px;
}

.G-ml-1 {
  margin-left: 10px;
}

.G-ml-2 {
  margin-left: 20px;
}

.G-ml-3 {
  margin-left: 30px;
}

.G-ml-4 {
  margin-left: 40px;
}

.G-ml-5 {
  margin-left: 50px;
}

.G-ml-6 {
  margin-left: 60px;
}

.G-btn {
  padding        : 0 50px;
  height         : $input-height;
  display        : flex;
  align-items    : center;
  justify-content: center;
  position       : relative;
  color          : $white;
  background     : transparent linear-gradient(90deg, #033656 0%, #109988 100%) 0% 0% no-repeat padding-box;
  border         : 0;
  border-radius  : 32px;
  letter-spacing : 3.28px;
  font-size      : 20px;
  font-family    : Regular;
  cursor         : pointer;
  text-transform : uppercase;
  position       : relative;
  overflow       : hidden;
  background     : linear-gradient(45deg);

  &::before {
    position        : absolute;
    content         : "";
    display         : inline-block;
    top             : -180px;
    left            : 0;
    width           : 30px;
    height          : 100%;
    background-color: #fff;
    animation       : shiny-btn1 3s ease-in-out infinite;
  }

  @keyframes shiny-btn1 {
    0% {
      -webkit-transform: scale(0) rotate(45deg);
      opacity          : 0;
    }
    80% {
      -webkit-transform: scale(0) rotate(45deg);
      opacity          : 0.5;
    }
    81% {
      -webkit-transform: scale(4) rotate(45deg);
      opacity          : 1;
    }
    100% {
      -webkit-transform: scale(50) rotate(45deg);
      opacity          : 0;
    }
  }

  * {
    color: $white;
  }

  &.G-small {
    font-size: 14px !important;
    padding  : 0 15px;
    height   : 30px;
  }

  &.G-smallest {
    font-size: 11px !important;
    padding  : 0 5px;
    height   : 25px;
  }

  &.G-black-btn {
    background: $black;
  }

  &.G-red-btn {
    background: $red;
  }

  &.G-gray-btn {
    background: $light-gray;
  }

  &.G-green-btn {
    background: $green;
  }

  &.G-white-btn {
    background: $white;

    * {
      color: $black;
    }
  }
}

@media (max-width: 600px) {
  .G-page {
    padding: 0 40px;
  }

  .G-btn {
    height   : $input-height - 10px;
    font-size: 16px;
  }

  .P-course-details {
    margin-bottom: 50px !important;
    .P-course-top {
      padding: 0 20px !important;

      .P-course-title {
        h2 {
          font-size: 17px !important;
        }

        h3 {
          font-size: 15px !important;
        }
      }

      .P-course-animation {
        div {
          width: 120px !important;
        }
      }
    }
  }

  .P-top-footer {
    height: 150px !important;
    > div {
      flex-wrap: wrap;
      padding  : 10px !important;
      div {
        width          : 250px;
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        font-size      : 12px !important;
        margin-left    : 13px;

        span {
          font-size: 12px !important;
        }
      }
    }
  }

  .P-course-details-content {
    padding       : 20px !important;
    flex-direction: column;
    .P-course-collapse {
      .P-arrow {
        width: 10px !important;
      }

      p {
        &:nth-child(1) {
          font-size: 10px !important;
        }

        &:nth-child(2) {
          font-size: 10px !important;
        }
      }
      .Collapsible__trigger {
        span {
          right: -50% !important;
        }
      }

      .P-title {
        font-size: 10px !important;
      }

      .P-description {
        font-size: 12px !important;
      }
    }

    .P-details-tutor {
      margin: 0 auto;
      .P-tutor-description {
        p {
          font-size: 16px !important;

          &:nth-child(2) {
            font-size: 18px !important;
          }
        }
      }
    }

    button {
      height       : 35px !important;
      font-size    : 16px !important;
      width        : 200px !important;
      margin-top   : 20px !important;
      margin-bottom: 40px !important;
    }
  }
}

.P-item-details {
  min-height      : 100vh;
  background-color: $background;
  .P-details-item {
    width: 100%;

    .P-details-image {
      width                : 50%;
      min-height           : 100vh;
      background-size      : contain;
      background-attachment: fixed;
    }

    .P-details-description {
      width  : 50%;
      display: flex;
      padding: 0 50px;

      h1 {
        font-size  : 35px;
        font-family: Bold;
        text-align : center;
      }

      h3 {
        text-align : justify;
        margin-top : 20px;
        font-size  : 22px;
        font-family: Regular;
      }

      h4 {
        text-align : justify;
        margin-top : 30px;
        font-size  : 18px;
        font-weight: 100;
        font-family: Regular;
      }
    }
  }
}

@media (max-width: 600px) {
  .P-item-details {
    .P-details-item {
      flex-direction: column !important;
      align-items   : center !important;
      width         : 100% !important;
      .P-details-image {
        width : 90%;
        height: 200px !important;
        background-attachment: unset !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;      
        background-position: center !important;  
        
        &:last-child {
          margin-top: 60px !important;
        }
      }

      .P-details-description {
        width: 100% !important;
        h1 {
          text-align: center;
          font-size : 22px;
        }

        h3 {
          font-size: 18px;
        }

        h4 {
          font-size: 16px;
        }
      }
    }
  }
}
